export default [
    {
        name: "Discord",
        picture: "/img/social-media-logos/discord.png",
        link: "//discord.sigapp.club"
    },
    {
        name: "GitHub (Purdue Internal)",
        picture: "/img/social-media-logos/github.png",
        link: "//github.itap.purdue.edu/acm-sigapp"
    },
    {
        name: "Google Drive",
        picture: "/img/social-media-logos/gdrive.png",
        link: "//drive.google.com/drive/folders/0ByBsXZAwFI0qSzY4NGFWeUZqeEU?usp=sharing"
    },
    {
        name: "Facebook",
        picture: "/img/social-media-logos/facebook.png",
        link: "//www.facebook.com/groups/PurdueACMSigApp/"
    },
];