export default {
	officers: {
		"President": [
			{
				name: "Ian Ryan",
				picture: "/img/people/ian.jpg",
				link: "mailto://iryan@purdue.edu"
			}
		],
		"Vice President": [
			{
				name: "Nachiket Ingle",
				picture: "/img/people/nachiket.png",
				link: "mailto://ningle@purdue.edu"
			},
			{
				name: "Jack Sovich",
				picture: "/img/people/jack.jpg",
				link: "mailto://jsovich@purdue.edu"
			}
		],
	},
	calendar: [
		{
			date: "8/31",
			title: "SIGAPP Callout",
			description: "Come learn about SIGAPP!"
		},
		{
			date: "9/2",
			title: "First Meeting",
			description: "Our first proper meeting of the semester"
		}
	]
};
